import(/* webpackMode: "eager" */ "/app/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/layout/fonts/FontsEntry.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./HelveticaNowDisplay-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./HelveticaNowDisplay-Md.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./HelveticaNowDisplay-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"ui-sans-serif\"],\"variable\":\"--font-display\"}],\"variableName\":\"HelveticaNowDisplay\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/layout/fonts/FontsEntry.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./HelveticaNowText-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./HelveticaNowText-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./HelveticaNowText-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"ui-sans-serif\"],\"variable\":\"--font-text\"}],\"variableName\":\"HelveticaNowText\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/layout/fonts/FontsEntry.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./KHGiga-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./KHGiga-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-khgiga\"}],\"variableName\":\"KHGiga\"}");
import(/* webpackMode: "eager" */ "/app/src/components/providers/CTAProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/providers/StoryblokProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/providers/TrackingProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui-library/footer/DownloadApps.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui-library/header/Header.tsx");
import(/* webpackMode: "eager" */ "/app/src/layout/global.css");
import(/* webpackMode: "eager" */ "/app/src/store/ConfigStoreInitializer.tsx")